import { Box, Button, Card, Paper, Stack, styled, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Texts from '../Components/Texts/Texts';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { LineChart } from '@mui/x-charts/LineChart';
import { useNavigate } from 'react-router-dom';

/* Source Data */
// Condensate Return (Kg)
const X = [85418, 88589, 78026, 97804, 65951, 120471, 111574, 41010, 48476, 48954, 46728, 46042, 40186, 30288, 28504, 43754, 89560, 105960, 110752, 103810, 110060, 118916, 113506, 91123, 118068, 126572, 107261, 47392, 83738, 109338]; 

// Utility Steam (Kg)
const Y = [4059, 4032, 4142, 4235, 4095, 4304, 4409, 3584, 3838, 3450, 3144, 4473, 3633, 3547, 3782, 3610, 5336, 4434, 4319, 4147, 6233, 6129, 4289, 4437, 6271, 8720, 5055, 5155, 4346, 4498];

// Styled Hidden Input
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

// Regression Process
function linearRegression(X, Y) {
  const n = X.length;

  // Calculate means of X and Y
  const meanX = X.reduce((sum, x) => sum + x, 0) / n;
  const meanY = Y.reduce((sum, y) => sum + y, 0) / n;

  // Calculate the terms needed for the numerator and denominator of beta
  let numerator = 0;
  let denominator = 0;
  for (let i = 0; i < n; i++) {
      numerator += (X[i] - meanX) * (Y[i] - meanY);
      denominator += (X[i] - meanX) ** 2;
  }

  // Calculate coefficients
  const beta = numerator / denominator;
  const alpha = meanY - (beta * meanX);

  return { alpha, beta };
};

export default function Input() {
  const navigate = useNavigate();
  const [xData, setXData] = useState([]);  // Uploaded xData
  const [approxData, setApproxData] = useState([]);  // Approximated yData
  const [polynomial, setPolynomial] = useState([]);  // Polynomial Coefficients

  // Handle File Upload
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const text = event.target.result;
    
      // Regular expression to split on any combination of spaces, tabs, newlines, or hyphens
      const numbers = text.split(/[\s,\n,\t,\-,]+/).map(line => parseFloat(line.trim())).filter(num => !isNaN(num));
    
      if (numbers.length > 0) {
        setXData(numbers);
      } else {
        alert('Text file is empty or contains invalid data. Please try again with a valid file.');
      }
    };
    

    if (file) {
      reader.readAsText(file);
    }
  };

  // Handle back button
  const handleBack = () => {
    //console.log('Back to home');
    navigate('/');
  }

  // Calculate Polynomial on Mount
  useEffect(() => {
    const { alpha, beta } = linearRegression(X, Y);
    //console.log(`The linear regression equation is: Y = ${beta.toFixed(2)} * X + ${alpha.toFixed(2)}`);
    setPolynomial([beta, alpha]);
  }, []);

  // Calculate Approximate Data Points
  useEffect(() => {
    if (xData.length > 0 && polynomial.length > 0) {
      //console.log('Calculating Approximations...');
      const approximations = xData.map(x => polynomial[0]*x + polynomial[1]);
      setApproxData(approximations);
    }
  }, [xData, polynomial]);

  return (
    <Box display={'flex'} justifyContent={'space-around'} flexDirection={'column'} alignItems={'center'} padding={'5%'}>
      {/* Instructions */}
      <Texts fontSize={40}>Instructions</Texts>
      <Box width={'max-content'} my={3} display={'flex'} flexDirection={'column'} gap={2}>
        <Texts>01. Open your Excel file containing the original data.</Texts>
        <Texts>02. Extract the Condensate Return (kg) data and save it as a .txt file.</Texts>
        <Texts>03. Ensure the .txt file contains only the data, with each value separated by spaces, newlines, tabs, or other common delimiters.</Texts>
        <Texts>04. Upload the .txt file here.</Texts>
      </Box>

      {/* Upload Button */}
      <Stack direction={'row'} spacing={2} mt={1} mb={2}>
        <Button variant="contained" onClick={handleBack}>
          <Texts whiteSpace='noWrap' fontSize={14}>Back to home</Texts>
        </Button>
        
        <Button
          component="label"
          variant="contained"
          startIcon={<CloudUploadIcon />}

        >
          <Texts whiteSpace='noWrap' fontSize={14}>Upload File</Texts>
          <VisuallyHiddenInput type="file" accept='.txt' onChange={handleFileUpload} />
        </Button>
      </Stack>
      
      {xData.length>0 && approxData.length>0 ?
        <>
          {/* Display Table */}
          <Card sx={{ my: 2, maxWidth: '100%', width:'100%', overflow:'auto', textAlign:'center' }}>
            <Texts color={'black'} mt={2} fontSize={22}>Tabulated Data</Texts>
            <TableContainer component={Paper} >
              <Table sx={{ minWidth: 650 }}>
                <TableBody>
                  <TableRow>
                    <TableCell align='center'>
                      <Texts color={'black'}>Condensate Return (kg)</Texts>
                    </TableCell>
                    {xData.map((point, idx) => (
                      <TableCell key={idx} align='center'>
                        <Texts color={'black'}>{point.toFixed(2)}</Texts>
                      </TableCell>
                    ))}  
                  </TableRow>

                  <TableRow>
                    <TableCell align='center'>
                      <Texts color={'black'}>Approximate Utility steam (kg)</Texts>
                    </TableCell>
                    {approxData.map((point, idx) => (
                      <TableCell key={idx} align='center'>
                        <Texts color={'black'}>{point.toFixed(3)}</Texts>
                      </TableCell>
                    ))}  
                  </TableRow>              
                </TableBody>
              </Table>
            </TableContainer>
          </Card>

          {/* Display Graph */}
          <Card sx={{ width: '100%', pl:1, py:1, mt:2 }}>
            <LineChart
              series={[
                { data: approxData, label: 'Approximate Utility steam (kg)' },
                //{ data: Y, label: 'Actual Utility steam (kg)' },
              ]}
              height={400}
              xAxis={[{ scaleType: 'point', data: xData, label:'Condensate Return (kg)' }]}
            />
          </Card>
        </> : <>
          <Card sx={{ my: 2, maxWidth: '100%', width:'100%', overflow:'auto', textAlign:'center', p:3, bgcolor:'#e6e6e6' }}>
              <ErrorOutlineIcon sx={{fontSize:'70px', color:'#737373'}}/>
              <Texts color={'#737373'} fontSize={30}>No Data</Texts>
          </Card>
        </>
      }
    </Box>
  );
}

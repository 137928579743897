import { Box, Button, Stack} from '@mui/material'
import React from 'react'
import Texts from '../Components/Texts/Texts'
import { useNavigate } from 'react-router-dom'

export default function Home() {
  const navigate = useNavigate();

  return (
    <Box display={'flex'} justifyContent={'space-around'} flexDirection={'column'} alignItems={'center'} padding={'5%'} height={'100vh'}>
      <Stack spacing={2}>
        <Texts fontSize={50}>Welcome</Texts>
        <Button variant='contained' onClick={()=>navigate('/input')}>Click to Continue</Button>
      </Stack>
    </Box>
  )
}

import { Typography } from '@mui/material'
import React from 'react'

export default function Texts({fontSize=16, fontWeight='bold', ...props}) {
  return (
    <Typography 
    color={'white'}
    fontSize={`${fontSize}px`}
    fontWeight={fontWeight}
    fontFamily={'roboto'}
    {...props}
    />
  )
}

import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import Input from "./Pages/Input";
import { Box } from "@mui/material";

function App() {
  return (
    <Box minWidth={'100vw'} minHeight={'100vh'} bgcolor={'#333333'}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to={'/home'}/>}/>
          <Route path="/home" element={<Home/>}/>
          <Route path="/input" element={<Input/>}/>
        </Routes>
      </BrowserRouter>
    </Box>
  );
}

export default App;
